import React from 'react';
import axios from "axios";
import loading from "./../../assets/images/loading-transparent.gif";
import oceanBG2 from "./../../assets/images/ocean-bg-3.jpeg";

class Portfolio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {projects: []};
    }
    componentDidMount() {
        // setTimeout(function(){
        //     // window.scrollTo({
        //     //     top: 0,
        //     //     behavior: "smooth"
        //     // });
        //     alert("bing bong");
        // }, 1000);
        
        const portfoliosURL = 'https://portfolio-wp.blainealbert.com/wp-json/wp/v2/portfolio';
        //console.log("test");
        axios.get(portfoliosURL)
        .then((response) => {
            //console.log(response);
            //this.customerList = response.data;
            this.setState({projects : response.data}); 
            //console.log(this.state.projects);
        })
        .catch((error) => {
            console.log("error: ", error);
        });
    }

    render() {
        return (
            <>
                <main className="portfolio-page">
                    <section className="page-banner portfolio__header text-center bg--sec text--light">
                        <h1>Portfolio</h1>

                    </section>
                    {/* <section className="portfolio-intro">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-6 text-center">
                                    <div id="portrait-col">
                                        <p><img style={{borderRadius: "0 0 50% 50%"}} src={certifications} alt="" width="378" height="auto" /></p>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    Here are some projects that I have worked on in the past. Please feel free to reach out to me if you want to know more.
                                </div>
                            </div>
                        </div>
                    </section> */}
                    <section className="projects" style={{backgroundImage: `linear-gradient(45deg,#f79a1a79,#1b9ce533,#6cdaee22), url(${oceanBG2})`}}>
                        <div className="container">
                            <div className="row">
                                {this.state.projects?.map((d, index) => {
                                    return (
                                        <div key={index} className="projects__col col-12 col-md-6 col-xl-4">
                                            <div className="projects__project-item text-center ">
                                                {d.acf.image && (
                                                    <img
                                                    src={d.acf.image.sizes.large}
                                                    alt={d.acf.image.alt}
                                                    className="projects__project-img"
                                                    width="100%"
                                                    />
                                                )}
                                                <div className="projects__content">
                                                    <h4 className="projects__poject-item-header">
                                                        {d.acf.title}
                                                    </h4>
                                                    

                                                    <div className="projects__description"dangerouslySetInnerHTML={{
                                            __html: d.acf.description, }}></div>
                                                    { d.acf.link !== null && d.acf.link !== "" &&
                                                        <a
                                                            href={d.acf.link.url}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="projects__project-link  btn--pri"
                                                        >
                                                            {d.acf.link.title}
                                                        </a>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                { this.state.projects.length === 0 &&
                                     <img
                                        src={loading}
                                        alt="loading"
                                        className="loading-img"
                                        width="50px"
                                        height="auto"
                                    />
                                }
                            </div>
                        </div>
                    </section>
                </main>
            </>
        );
    }
}
export default Portfolio;