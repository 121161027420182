import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';
import code from './../assets/svg/code-solid.svg';

function Navbar() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  return (
    <>
      <nav className="navbar navbar-expand-md navbar-light">
        <NavHashLink className="navbar-brand text-info font-weight-bolder no-underline" to="/#">
          <img
            src={code}
            alt="Logo"
            width="100px"
            height="auto"
            className="navbar-logo"
          />
          <span>Blaine Albert</span>
        </NavHashLink>
        <button
          className="custom-toggle navbar-toggle"
          type="button"
          data-toggle="collapse"
          data-target="#navbar__links"
          aria-controls="navbar__links"
          aria-expanded={!isNavCollapsed ? true : false}
          aria-label="Toggle navigation"
          onClick={handleNavCollapse}
        >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
        </button>

        <div
          className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
          id="navbar__links"
        >
          <NavHashLink
            className="nav-link "
            activeStyle={{ color: "" }}
            to="/resume/#top"
          >
            Resume
          </NavHashLink>
          <NavHashLink
            className="nav-link "
            activeStyle={{ color: "" }}
            to="/portfolio/#top"
          >
            Portfolio
          </NavHashLink>
          <NavHashLink
            className="nav-link nav-contact"
            activeStyle={{ color: "" }}
            to="/#contact"
          >
            Contact
          </NavHashLink>
          {/* <HashLink
            className="nav-link nav-contact"
            activeStyle={{ color: "" }}
            to="/#contact"
            scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
          >
            Contact
          </HashLink> */}
        </div>
      </nav>
    </>
  );
}
export default Navbar;