import React from 'react';
import oceanBG4 from "./../../assets/images/ocean-bg-9.jpeg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Resume extends React.Component {
    componentDidMount() {
        // window.scrollTo({
        //     top: 0,
        //     behavior: "smooth"
        // });
    }
    render() {
        return (
            <>
              
                <main className="resume-page">
                    <section className="page-banner resume__header text-center bg--sec text--light">
                        <h1>Resume</h1>
                    </section>
                    <section className="resume" style={{backgroundImage: `linear-gradient(315deg,#f79a1a44,#1b9ce522,#6cdaee22), url(${oceanBG4})`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-left">
                                    <div className="resume__content">
                                        <h3 className="">Bio <FontAwesomeIcon icon="id-card" /></h3>
                                        <p>I graduated in 2015 from the UofS. I majored in interactive systems design offered through the Department of Computer Science. My discipline was focused in UI/UX design and full stack development.</p>
                                        <p>Since graduating, I have worked mainly as a web and app developer. I moved out to Vancouver in 2017 to pursue my career goals.</p>
                                        <p>if you would like to know anything else, please <a className="link--orange" href="/#contact">contact me</a>.</p>
                                        <hr className="hr--overflow" />
                                        <h3 className="">Skills and Technologies <FontAwesomeIcon icon="hammer" /></h3>
                                        <ul className="resume__skill-list">
                                            <li>HTML</li>
                                            <li>CSS</li>
                                            <li>JavaScript</li>
                                            <li>VueJS &amp; NuxtJS</li>
                                            <li>ReactJS &amp; NextJS</li>
                                            <li>jQuery</li>
                                            <li>NodeJS</li>
                                            <li>PHP</li>
                                            <li>C#</li>
                                            <li>Swift</li>
                                            <li>Python</li>
                                            <li>Unity</li>
                                            <li>iOS</li>
                                            <li>Bootstrap</li>
                                            <li>WordPress</li>
                                            <li>Shopify</li>
                                            <li>SQL</li>
                                            <li>Git</li>
                                        </ul>
                                        <hr className="hr--overflow" />
                                        <h3 className="">Work History  <FontAwesomeIcon icon="clock" /></h3>
                                        <ul>
                                            <li><b>Web Developer</b> | 2019 - 2021 <br/><a className="link--orange" target="_blank" rel="noreferrer" href="https://www.ballisticarts.com/">Ballistic Arts Media Studios</a></li>
                                            <li><b>Web Developer &amp; UX/UI Designer</b> | 2018 - 2019 <br/><a className="link--orange" target="_blank" rel="noreferrer" href="https://www.hotsoupgroup.com/">Hot Soup Marketing Group</a></li>
                                            <li><b>Web Developer</b> | 2016 - 2018 <br/><a className="link--orange" target="_blank" rel="noreferrer" href="http://refresh.ca/">Refresh Inc.</a></li>
                                            <li><b>Developer</b> | 2015 - 2016 <br/><a className="link--orange" target="_blank" rel="noreferrer" href="https://vie.works/">Vie Works</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </>
        );
    }
}
export default Resume;