import { NavLink } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';


function Footer() {
  return (
    <footer>
        <div className="footer__container bg--sec text--light">
            <div className="footer__links text-center">
                <NavHashLink
                    className="footer__link "
                    exact={true}
                    activeStyle={{ color: "" }}
                    to="/#"
                >
                    Home
                </NavHashLink>
                <NavLink
                    className="footer__link"
                    activeStyle={{ color: "" }}
                    to="/resume/"
                >
                    Resume
                </NavLink>
                <NavLink
                    className="footer__link"
                    activeStyle={{ color: "" }}
                    to="/portfolio"
                >
                    Portfolio
                </NavLink>
            </div>
            <div className="footer__copyright">
                <p className="text-center">Website designed by Blaine Albert, copyright  &copy;{new Date().getFullYear()}. All rights reserved.</p>
            </div>
        </div>
    </footer>
  );
}
export default Footer;
