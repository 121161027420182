import React from "react";
import { BrowserRouter as HashRouter, Route, Switch } from 'react-router-dom';

import Home from "./components/pages/home";
import Portfolio from "./components/pages/portfolio";
import Resume from "./components/pages/resume";
import PageNotFound from "./components/pages/404";

import Navbar from "./components/navbar";
import Footer from "./components/footer";

import {Helmet} from "react-helmet";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faCoffee, faIdCard, faHammer, faClock  } from '@fortawesome/free-solid-svg-icons';

// import oceanBG4 from "./assets/images/ocean-bg-9.jpeg";
// import oceanBG2 from "./assets/images/ocean-bg-3.jpeg";



library.add(fab, faCheckSquare, faCoffee, faIdCard, faHammer, faClock);


function App() {
  return (
    <>
      <HashRouter>
          <Helmet>
            
          </Helmet>
          <Navbar/>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/portfolio/" component={Portfolio} />
            <Route exact path="/resume" component={Resume} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        <Footer/>
      </HashRouter>
      
      
    </>
  );
}

export default App;
