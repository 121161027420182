// import { Link } from "react-router-dom";
import React from 'react';
import sharky from "./../../assets/images/404.gif";

class PageNotFound extends React.Component{
    render(){
        return(
            <>
                <main className="404-page__container">
                    <section className="page-banner 404-page__header text-center bg--sec text--light">
                        <h1>404 - Page Not Found</h1>
                    </section>
                    <section className="section-404">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h2>Nothing to see here!</h2>
                                    <p>You have explored beyond the depths. Please click the button to return to safe waters.</p>
                                    <a className="btn--pri" href="/">Take me back</a>
                                    <img
                                        className="section-404__gif fadeIn"
                                        alt="left shark"
                                        src={sharky}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </>
        )
    }
}

export default PageNotFound;